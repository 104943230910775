<template>
  <div>
    <CCard>
      <CCardHeader>
        <CIcon name="cil-grid"/> Cancelled Life Policies
      </CCardHeader>
      <PoliciesTable />
    </CCard> 
  </div>
</template>

<script>
import PoliciesTable from "@/components/CancelledLifeTable"
export default {
  components:{
    PoliciesTable
  }
}
</script>

<style>

</style>